body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(./../assets/bg.jpg) url(./../assets/bg-adv.jpg);
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #333;
  overflow: hidden;
  height: 100%;
}

body {
  background-color: #000;
  background-image: url(./../assets/bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transition: background 6s;
}

#root {
  min-height: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

html,
body {
  -ms-overflow-style: none;
  overflow: auto;
}

a:focus {
  outline: none;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.not-found h1 {
  font-size: 3rem;
  color: #0094ff;
}

.not-found .inn {
  background-color: rgba(255, 255, 255, 0.9);
  width: 320px;
  text-align: center;
  padding-bottom: 20px;
}

.not-found p {
  padding: 10px;
  line-height: 130%;
}










.i-left {
  width: 25%;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 148, 255, 0.8);
  overflow-y: scroll;
  transition: background-color 3s;
}

.i-left::-webkit-scrollbar {
  display: none;
}

.il-tpl {
  text-decoration: none;
}

.il-top {
  padding: 40px 5px 40px 5px;
}

.il-img-c {
  width: 156px;
  height: 156px;
  margin: 0px auto;
  position: relative;
}

.il-img {
  width: 156px;
  display: block;
  border-radius: 156px;
  transition: opacity 3s;
}

.il-img-adv {
  position: absolute;
  width: 156px;
  border-radius: 156px;
  left: 0;
  opacity: 0;
  transition: opacity 3s;
}

.il-name {
  padding-top: 4px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  text-shadow: 0px 1px 0px #005fa3;
  color: #fff;
}

.il-title {
  color: #fff;
}

.i-left ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.i-left li a {
  display: block;
  color: #ddd;
  padding: 6px 10px;
  text-decoration: none;
  transition: color .5s ease;
}

.i-left li a:hover {
  color: #fff;
}

.i-left li .active {
  color: #fff;
}


.i-right {
  margin-left: 25%;
  min-height: 100vh;
  background-color: #fff;
  transition: background-color 3s;
  animation: right-load 800ms ease-in;
}

.ir-inner {
  padding: 50px 50px;
  color: #777;
  line-height: 26px;
}

.ir-h {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

/* Home page settings */
.h-right {
  margin-left: 25%;
  animation: home-load 1200ms ease-in;
}

.hr-inn {
  padding: 120px 20px 20px 60px;
  max-width: 800px;
  font-family: 'Open Sans', sans-serif;
}

.hr-ha {
  font-size: 56px;
  font-weight: bold;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .6);
  display: inline-block;
  margin-bottom: 20px;
}

.hr-hb {
  font-size: 30px;
  line-height: 48px;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .8);
  font-weight: 100;
  margin-bottom: 12px;
  display: inline-block;
}

.hr-btn {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  padding: 8px 16px;
  background-color: #0094ff;
  border-radius: 4px;
  margin-right: 6px;
  border: 0px;
  outline: none;
  cursor: pointer;
  transition: background-color 3s;
}

.hr-btn:hover {
  background: #0085e4;
}


.pr-c {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.pr-c li {
  overflow: auto;
  position: relative;
}

.pr-tm {
  width: 100px;
  line-height: 20px;
  padding: 10px 16px 20px 24px;
  float: left;
  border-right: 1px solid #ddd;
  text-align: right;
  position: absolute;
  bottom: 0;
  top: 0;
}

.pr-cn {
  margin-left: 140px;
  padding: 3px 20px 80px 20px;
  line-height: 30px;
}

.pr-dot {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background: #0094ff;
  position: absolute;
  top: 15px;
  right: -4px;
}

.pr-title {
  text-decoration: none;
  color: #555;
  font-size: 24px;
}

.pr-imgc {
  padding-top: 10px;
  overflow: auto;
}

.pr-imgc .pr-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: top center;
  display: block;
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
  border-radius: 5px;
}

.p-tech {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 10px;
  background: #0094ff;
  color: #fff;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 4px;
  transition: background-color 3s;
}

.sk-right {
  margin-left: 25%;
  background: #fff;
  min-height: 100%;
  transition: background-color 3s;
  animation: right-load 800ms ease-in;
}

.skr-inner {
  padding: 50px 50px;
  color: #777;
  line-height: 26px;
  max-width: 840px;
}

.sk-img {
  display: block;
  width: 100%;
}

.sk-cc {
  margin-bottom: 80px;
}

.sk-hh {
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
}


.sk-prog {
  padding-bottom: 30px;
}

.prog-h {
  font-size: 16px;
  line-height: 20px;
  padding: 5px 0px;
}

.prog-p {
  float: right;
}

.prog-b {
  width: 100%;
  background: #ddd;
  height: 4px;
}

.prog-b div {
  background-color: #0094ff;
  height: 4px;
}

.ct-dets {
  font-size: 16px;
  line-height: 26px;
}

.ct-dets a {
  text-decoration: none;
  color: #0094ff;
}

.ct-dets i {
  vertical-align: middle;
  color: #000;
}

.ct-det {
  padding: 7px 0px;
}

.love {
  color: #fff;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 5px 8px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, .4);
}

.love i {
  color: #FE251B;
}



.bulk-imgs {
  width: 100%;
  min-height: 100%;
  background: #ddd;
}

.bulk-imgs img {
  display: block;
  margin-bottom: 6px;
  max-width: 100%;
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
}

.imagePop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 99999;
}

.imagePop .close {
  position: absolute;
  width: 10%;
  right: 0;
  top: 20px;
  cursor: pointer;
}

.imagePop .close img {
  display: block;
  width: 60%;
  padding: 20% 20%;
}

.imagePop .imageScroll {
  height: 100%;
  overflow-y: scroll;
}

.imagePop .imageScroll img {
  display: block;
  width: 80%;
  height: auto;
  max-width: 1200px;
  margin: 20px auto;
  float: unset;
  animation: image-load 500ms ease-in;

}



/* Adventure Mode */
.adv-mode {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
}

.adv-mode button {
  background-color: #000;
}

.adv-mode button:hover,
.adv-mode button:focus {
  background-color: #000;
}

.adv-term {
  position: absolute;
  top: 10%;
  left: 25%;
  right: 25%;
  bottom: 10%;
  background-color: #000;
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .2);
  z-index: 9999;
  transform: translateY(-200%);
}

.adv-term-h {
  padding: 8px 12px;
}

.adv-term-mac {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-right: 12px;
  cursor: pointer;
}

.adv-term-in {
  padding: 8px 12px;
  color: #aaa;
  font-size: 14px;
  line-height: 24px;
  height: calc(100% - 56px);
  overflow: scroll;
}

.adv-term-input {
  border: 0;
  padding: 0;
  color: #fff;
  outline: none;
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 24px;
}


.adventure {
  background-image: url(./../assets/bg-adv.jpg);
}

.adventure .i-left {
  background-color: rgba(0, 0, 0, .8);
  animation: adventure-left 3s ease-in 5500ms;
  animation-fill-mode: forwards;
}

.adventure .hr-btn {
  background-color: #777;
}

.adventure .i-right,
.adventure .sk-right {
  background-color: #000;
  animation: adventure-right 3s ease-in 5500ms;
  animation-fill-mode: forwards;
}

.adventure .h-right {
  animation: adventure-right 3s ease-in 5500ms;
  animation-fill-mode: forwards;
}

.adventure .ct-dets i {
  color: #777;
}

.adventure .p-tech {
  background-color: #777;
}

.adventure .adv-term {
  animation: adventure-term 3s ease-in 7500ms;
  animation-fill-mode: forwards;
}

.adventure .il-img {
  opacity: 0;
}

.adventure .il-img-adv {
  opacity: 1;
}

















@keyframes home-load {
  0% {
    transform: translateX(40px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes right-load {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes image-load {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes adventure-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes adventure-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes adventure-term {
  0% {
    transform: translateY(-200%);
  }

  100% {
    transform: translateY(0);
  }
}













@media only screen and (max-width: 800px) {
  .i-left {
    width: 100%;
    float: none;
    position: static;
    padding-bottom: 8px;
  }

  .h-right,
  .i-right,
  .sk-right {
    margin-left: 0;
  }

  .love {
    position: relative;
  }

  nav ul li {
    float: left;
    width: 25%;
  }

  .ir-inner,
  .ct-inn,
  .skr-inner {
    padding: 50px 25px;
  }

  .pr-tm {
    display: none;
  }

  .pr-cn {
    margin-left: 0;
  }

  .hr-inn {
    padding: 80px 20px 20px 50px;
  }

  .love {
    padding: 80px 20px 20px 50px;
  }

  .adv-mode {
    display: none;
  }

}